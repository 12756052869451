import React, { useRef } from 'react'
import './Contact.css'
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_cl2xjcz', 'template_s7uxzie', form.current, 'KTWdSBpQOVHrtsfVv')
     e.target.reset()
  };

  return (
    <section className="contact section" id="contact">
      <div className="titles">
        <h2 className="section__title">Get in touch</h2>
        <span className="section__subtitle">
            Contact me
        </span>
      </div>

        <div className="contact__container container grid">
          <div className="contact__content">
            <h3 className="contact__title">Talk to me</h3>

            <div className="contact__info">
              <div className="contact__card">
                <i className="bx bx-mail-send contact__card-icon"></i>

                <h3 className="contact__card-title">Email</h3>
                <span className="contact__card-data">tackwouter@hotmail.com</span>

                <a href="mailto:tackwouter@hotmail.com" className="contact__button">Write me 
                <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
              </div>

              <div className="contact__card">
                <i className="bx bxl-whatsapp contact__card-icon"></i>

                <h3 className="contact__card-title">Phone</h3>
                <span className="contact__card-data">+32 487 691 703</span>

                <a href="https://api.whatsapp.com/send?phone=+32487691703&text=Hello!" className="contact__button">Write me 
                <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
              </div>

             

            </div>
          </div>

          <div className="contact__content">
            <h3 className="contact__title">Write me a message</h3>

            <form ref={form} onSubmit={sendEmail} className="contact__form">
              <div className="contact__form-div">
                <label className="contact__form-tag">Name</label>
                <input type="text" className="contact__form-input" name="name" placeholder='Insert your name' />
              </div>

              <div className="contact__form-div">
                <label className="contact__form-tag">Mail</label>
                <input type="email" className="contact__form-input" name="email" placeholder='Insert your email' />
              </div>

              <div className="contact__form-div contact__form-area">
                <label className="contact__form-tag">Message</label>
                <textarea name="message" className="contact__form-input" placeholder='Write your message' cols="30" rows="10"></textarea>
              </div>

              <button className="button button--flex">
                Send Message
                <i className="uil uil-message button__icon"></i>
              </button>
            </form>
          </div>
        </div>
    </section>
  )
}

export default Contact